/*
Copyright 2022 - 2023 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import {
  FC,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
  Key,
  FormEvent,
  FormEventHandler,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { Room } from "matrix-js-sdk";
import { Button, Text } from "@vector-im/compound-web";
import LinkIcon from "@vector-im/compound-design-tokens/icons/link.svg?react";
import CheckIcon from "@vector-im/compound-design-tokens/icons/check.svg?react";
import CloseIcon from "@vector-im/compound-design-tokens/icons/close.svg?react";
import useClipboard from "react-use-clipboard";
import { FieldRow, InputField, ErrorMessage } from "../input/Input";
import { Form } from "../form/Form";
import { Modal } from "../Modal";
import { TabContainer, TabItem } from "../tabs/Tabs";
import { getAbsoluteRoomUrl } from "../matrix-utils";
import styles from "./InviteModal.module.css";
import { useRoomSharedKey } from "../e2ee/sharedKeyManagement";
import { Toast } from "../Toast";
import { logger } from "matrix-js-sdk/src/logger";
import { Config } from "../config/Config";
import { Caption } from "../typography/Typography";

interface Props {
  room: Room;
  open: boolean;
  onDismiss: () => void;
}

type InviteTab = "link" | "email";

export const InviteModal: FC<Props> = ({ room, open, onDismiss }) => {
  const { t } = useTranslation();
  const formRef = useRef(null);

  const [guestEmails, setGuestEmails] = useState<string[]>([]);
  const [tab, setTab] = useState<InviteTab>("link");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const roomSharedKey = useRoomSharedKey(room.roomId);
  const url = useMemo(
    () =>
      getAbsoluteRoomUrl(room.roomId, room.name, roomSharedKey ?? undefined),
    [room, roomSharedKey],
  );
  const [, setCopied] = useClipboard(url);
  const [toastOpen, setToastOpen] = useState(false);
  const onToastDismiss = useCallback(() => setToastOpen(false), [setToastOpen]);

  const onButtonClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setCopied();
      onDismiss();
      setToastOpen(true);
    },
    [setCopied, onDismiss],
  );

  const onTabChange = (t: InviteTab) => {
    setTab(t);
  };

  const addEmail: FormEventHandler<HTMLFormElement> = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const data = new FormData(e.target as HTMLFormElement);
      const email = (data.get("guestEmail") as string) ?? "";
      const isExists = guestEmails.includes(email);

      if (!isExists) {
        setGuestEmails([...guestEmails, email]);
        // @ts-expect-error
        formRef?.current?.reset();
      }
    },
    [guestEmails],
  );

  const removeEmail = useCallback(
    (email: string) => {
      setGuestEmails(guestEmails.filter((e) => e !== email));
    },
    [guestEmails],
  );

  const sendInvite = useCallback(async () => {
    try {
      setLoading(true);
      const razorObject = {
        IsCurrentMeetingAttr: "true",
        PasswordAttr: "",
        ImageLinkAttr:
          "https://element.meetsper.com/themes/element/img/logos/opengraph.png",
        MeetingUrlAttr: url,
        HtmlBodyAttr: "",
        ArrengerEmail: "muratersin@gmail.com",
      };
      const payload = {
        subject: "Bizcom",
        participantsEmail: guestEmails.join(", "),
        addCallendar: false,
        calendarTemplate: {
          startDate: {
            Seconds: 0,
            Nanos: 0,
          },
          endDate: {
            Seconds: 0,
            Nanos: 0,
          },
          shareParticipantList: false,
          summary: "Bizcom",
        },
        shareParticipantList: false,
        templateKey: "MeetingRequest",
        razorObj: JSON.stringify(razorObject),
      };

      const bizbizeApi = Config.get().bizbize_api;

      if (!bizbizeApi) {
        throw new Error('Missing config param: "bizbize_api"');
      }

      await fetch(`${bizbizeApi}/meet-orch/api/Communication/BizcomInvite`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
    } catch (err) {
      logger.error(err);
    } finally {
      setLoading(false);
    }
  }, [guestEmails]);

  return (
    <>
      <Modal title={t("invite_modal.title")} open={open} onDismiss={onDismiss}>
        <TabContainer
          onSelectionChange={onTabChange as (key: Key) => void}
          selectedKey={tab}
          className={styles.tabContainer}
        >
          <TabItem key="link" title={<span>{t("invite_by_link")}</span>}>
            <>
              <Text className={styles.url} size="sm" weight="semibold">
                {url}
              </Text>
              <Button
                className={styles.button}
                Icon={LinkIcon}
                onClick={onButtonClick}
                data-testid="modal_inviteLink"
              >
                {t("action.copy_link")}
              </Button>
              <Toast
                open={toastOpen}
                onDismiss={onToastDismiss}
                autoDismiss={2000}
                Icon={CheckIcon}
              >
                {t("invite_by_email")}
              </Toast>
            </>
          </TabItem>
          <TabItem key="email" title={<span>{t("invite_by_email")}</span>}>
            <Form ref={formRef} className={styles.form} onSubmit={addEmail}>
              <Caption>{t("guest_email_desc")}</Caption>
              <FieldRow className={styles.fieldRow}>
                <InputField
                  id="guestEmail"
                  name="guestEmail"
                  label={t("guest_email")}
                  placeholder={t("guest_email")}
                  type="email"
                  required
                  autoComplete="off"
                  data-testid="invite_guestEmail"
                />
              </FieldRow>
              <div className={styles.emailWrapper}>
                {guestEmails.map((email) => (
                  <span
                    className={styles.email}
                    onClick={() => removeEmail(email)}
                  >
                    <CloseIcon /> {email}
                  </span>
                ))}
              </div>
              {error && (
                <FieldRow className={styles.fieldRow}>
                  <ErrorMessage error={error} />
                </FieldRow>
              )}
            </Form>
            <Button
              size="lg"
              className={styles.button}
              disabled={loading || !guestEmails.length}
              onClick={sendInvite}
              data-testid="invite_submit"
            >
              {loading ? t("common.loading") : t("action.invite")}
            </Button>
          </TabItem>
        </TabContainer>
      </Modal>
    </>
  );
};
