/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable matrix-org/require-copyright-header */
import { logger } from "matrix-js-sdk/src/logger.js";

import KrispSDK, { IAudioFilterNode } from "../../public/libs/krispsdk.mjs";

const STORAGE_KEY = "noise_suppression";
enum KrispStatus {
  Enabled = "enabled",
  Disabled = "disabled",
}
let krispSDK: KrispSDK;
let filterNode: IAudioFilterNode;
let audioContext;
const _getUserMedia = window.navigator.mediaDevices.getUserMedia.bind(
  window.navigator.mediaDevices,
);

export const getKrispIsEnabled = (): boolean =>
  window.localStorage.getItem(STORAGE_KEY) !== KrispStatus.Disabled;

const checkStatusAndToggle = () => {
  const isDisabled =
    window.localStorage.getItem(STORAGE_KEY) === KrispStatus.Disabled;
  if (!isDisabled) {
    setNoiseSuppressionStatus(true);
  }
};

const setToLocalStorage = (value: KrispStatus) => {
  window.localStorage.setItem(STORAGE_KEY, value);
};

export const setNoiseSuppressionStatus = (isEnabled: boolean): void => {
  if (!filterNode) {
    logger.warn("Filternode is not created yet.");
    return;
  }

  if (filterNode.isEnabled() && isEnabled) {
    logger.warn("Noise suppression is already enabled.");
    return;
  }

  if (isEnabled) {
    filterNode.enable();
    setToLocalStorage(KrispStatus.Enabled);
    logger.info("Toggle Krisp ✓");
  } else {
    filterNode.disable();
    setToLocalStorage(KrispStatus.Disabled);
    logger.info("Toggle Krisp ✘");
  }
};

export const isInitialized = () => Boolean(filterNode);

export const getUserMediaWithNoiseSuppression = async (
  constraints?: MediaStreamConstraints | DesktopCapturerConstraints,
): Promise<MediaStream> => {
  audioContext = new AudioContext();
  if (constraints?.audio) {
    const defaultAudioOptions =
      typeof constraints?.audio === "object" ? constraints.audio : {};
    constraints.audio = {
      ...defaultAudioOptions,
      echoCancellation: true,
      noiseSuppression: false,
      autoGainControl: false,
    };
  }

  // @ts-expect-error ingnore
  const microphoneStream = await _getUserMedia(constraints);

  if (!krispSDK) {
    logger.warn("Krisp SDK is not supported");
    return microphoneStream;
  }

  const source = audioContext.createMediaStreamSource(microphoneStream);
  const destination = audioContext.createMediaStreamDestination();

  filterNode = await krispSDK.createNoiseFilter(
    audioContext,
    () => {
      logger.info("Krisp is working");
      checkStatusAndToggle();
    },
    () => {
      logger.info("Krisp is disposed");
    },
  );

  source.connect(filterNode);
  filterNode.connect(destination);

  return destination.stream;
};

export const initNoiseSuppression = async () => {
  try {
    if (!KrispSDK.isSupported()) {
      logger.warn("Krisp SDK is not supported");
      return;
    }

    krispSDK = new KrispSDK({
      params: {
        logProcessStats: false,
        useSharedArrayBuffer: false,
        debugLogs: false,
        models: {
          model8: "./libs/models/model_8.kw",
          model16: "./libs/models/model_16.kw",
          model32: "./libs/models/model_32.kw",
        },
      },
      callbacks: {},
    });

    await krispSDK.init();

    if (window?.navigator?.mediaDevices?.getUserMedia) {
      window.navigator.mediaDevices.getUserMedia = async function getUserMedia(
        constraints?: MediaStreamConstraints | DesktopCapturerConstraints,
      ): Promise<MediaStream> {
        if (constraints?.video) {
          return _getUserMedia(constraints);
        }

        return getUserMediaWithNoiseSuppression(constraints);
      };
    }
  } catch (err) {
    logger.error("Krisp init error: ", err);
  }
};
